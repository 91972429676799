import httpUtil from "@/utils/httpUtil";

/**获取个人佣金数据 */
export const queryCommissionRecord = async params => httpUtil.post("/api/crmPc/distributor/queryCommissionRecord", params)


/**获取个人佣金详情数据 */
export const queryCommissionRecordDetail = async params => httpUtil.post("/api/crmPc/distributor/queryCommissionRecordDetail", params)


/**获取员工佣金详情数据 */
export const queryCommissionRecordUser = async params => httpUtil.post("/api/crmPc/distributor/queryCommissionRecordUser", params)

/**获取员提现列表 */
export const queryWithdrawals = async params => httpUtil.post("/api/crmPc/distributor/queryWithdrawals", params)


/**佣金设置 */
export const updateSetting = async params => httpUtil.post("/api/crmPc/distributor/updateSetting", params)


/**设置详情 */
export const querySetting = async params => httpUtil.post("/api/crmPc/distributor/querySetting", params)


/**提现操作 */
export const makeMoney = async params => httpUtil.post("/api/crmPc/distributor/makeMoney", params)





/**提现设置 */
export const queryBossWithdrawalSetting = async params => httpUtil.post("/api/crmPc/companyGoods/queryBossWithdrawalSetting", params)


/**更新提现设置 */
export const updateBossWithdrawalSetting = async params => httpUtil.post("/api/crmPc/companyGoods/updateBossWithdrawalSetting", params)


/**可提现余额-查询公司账户 */
export const querySells = async params => httpUtil.post("/api/crmPc/companyGoods/querySells", params)

/**Boss提现列表查询 */
export const queryOrders = async params => httpUtil.post("/api/crmPc/companyGoods/queryOrders", params)


/**发起Boss提现申请 */
export const addBossWithdrawals = async params => httpUtil.post("/api/crmPc/companyGoods/addBossWithdrawals", params)

/**Boss提现列表查询 */
export const queryBossWithdrawals = async params => httpUtil.post("/api/crmPc/companyGoods/queryBossWithdrawals", params)


/**
 * 财务管理
 * Boss提现列表查询 */
export const bossSubordinationBalance = async params => httpUtil.post("/api/crmPc/companyGoods/bossSubordinationBalance", params)


/**Boss提现列表查询 */
export const bossSubordinationBalanceDetails = async params => httpUtil.post("/api/crmPc/companyGoods/bossSubordinationBalanceDetails", params)

/**提现审核操作 */
export const bossWithdrawalsReview = async params => httpUtil.post("/api/crmPc/companyGoods/bossWithdrawalsReview", params)

 



