<template>
  <div class="view pa24">
    <div class="d-flex mb20">
      <div class="bgf7fa flex-c-c flex-column hover_pointer pl30 pt20 pb60 mr20 pr30 posre">
          <p class="fs8  cA1">账户可提现余额</p>
          <p class="fs10 fwbold cA1">{{balance}}</p>
          <div class="d-flex flex-column align-items-center w100p posab" style="top:110px">
            <div class="d-flex align-items-center">
              <p>冻结佣金金额</p>
              <el-tooltip
                class="item"
                effect="dark"
                content='此冻结佣金为已完成分销订单中分销员的”未入账“和"未提现到账"分销佣金。'
                placement="top"
              >
                <i style="font-size: 16px" class="el-icon-question" />
              </el-tooltip>
              ￥{{frozenCommission}}
            </div>
            <div class="d-flex align-items-center">
              <p class="cblue  mr10" @click="dialogTableVisible = true">余额提现</p>
              <p class="cblue" @click="checkRecord('moneyRecord')">资金记录</p>
            </div>
          </div>
      </div>
      <div class="flex1 h170  bgf7fa pt20 pr20 pl20 d-flex flex-column">
        <div class="flex-a-b-c mb20">
          <div class="cA1 fs8 fwbold">余额提现动态</div>
          <div class="cblue fs8 hover_pointer" @click="checkRecord('WithdrawalRecord')">查看提现记录</div>
        </div>
        <div class="flex1 oyauto">
          <div class="flex-a-b-c mb20" style="padding-right: 200px" v-for="(item,index) in withdrawalList" :key="index">
            <div>{{item.createdTime}}</div>
            <div v-if="item.approvalStatus == 0">等待打款</div>
            <div v-if="item.approvalStatus == 1">打款成功</div>
            <div v-if="item.approvalStatus == 2">已驳回</div>
            <div v-if="item.approvalStatus == 3">审核通过待打款</div>
            <div>{{item.amount + '/余额' + item.balance + '元'}}</div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div v-for="(item,index) in dateList"
           @click="setDay(item.date)"
           :key="index"
           class="flex-c-c w80 h34 mr10 br5 hover_pointer"
           :class=" currentDateIndex == item.date ?  'bbule1 cblue trans2' : 'bDCDFE6 trans2' ">
          {{item.name}}
      </div>
      <el-date-picker
        v-model="pickerValue"
        class="mr10 "
        align="right"
        value-format="yyyy-MM-dd"
        type="daterange"
        @change="selectDate"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
      <el-button type="primary" @click="queryOrders">查询</el-button>
    <!-- <el-button type="primary" @click="queryOrders">导出</el-button> -->
    </div>
    
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column>
          <template slot="header">
            累计商品收入
            <el-tooltip
              class="item"
              effect="dark"
              content="累计商品金额-累计优惠金额-累计分销佣金金额"
              placement="top"
            >
              <i style="font-size: 16px" class="el-icon-question" />
            </el-tooltip>
            {{totalSells}}
          </template>
          <el-table-column
            prop="ordersNo"
            align="center"
            label="订单编号"
            show-overflow-tooltip
          />
          <el-table-column prop="goodsName" align="center" label="商品名称" width="200" show-overflow-tooltip/>
        </el-table-column>
        <el-table-column :label="`累计商品金额${totalPrice}`">
          <el-table-column prop="orderPrice" align="center" label="商品金额(原价)" />
        </el-table-column>
        <el-table-column :label="`累计优惠金额${totalDiscount}`">
          <el-table-column prop="discountAmount" align="center" label="优惠金额">
            <template slot-scope="scope">
              {{ scope.row.discountAmount ? scope.row.discountAmount : '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="payPrice" align="center" label="实付金额"/>
        </el-table-column>
        <el-table-column :label="`累计分销佣金金额${totalCommission}`">
          <el-table-column prop="orderType" align="center" label="订单类型" />
          <el-table-column prop="commission" align="center" label="分销佣金">
            <template slot-scope="scope">
                {{ scope.row.commission !== '0.00' ? scope.row.commission : '-' }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="forwardfNum" align="center" label="操作">
            <template>
              <div class="cblue hover_pointer">
                详情
              </div>
            </template>
          </el-table-column> -->
        </el-table-column>
        
      </template>
    </commonTable>
    <el-dialog title="余额提现" 
               width="40%"
               :center="true"
               :modal-append-to-body="false"
               :visible="dialogTableVisible"
               @close="dialogClose">
        <div class="pl20">
          <div class="flex-a-c  mb20">
            <div class="w200 text-align-right mr20 cA1 fs8 fwbold">
              收款账户：
            </div>
            <div class="flex-a-c">
              <p class="cA1 fs8 fwbold mr40">{{payee}}</p>
              <p class="fs7">提现账户默认为企业BOSS账户，不可更改</p>
            </div>
          </div>

          <div class="flex-a-c mb20">
            <div class="w200 text-align-right mr20 cA1 fs8 fwbold">
              提现金额：
            </div>
            <div class="flex-a-c">
              <el-input class="mr20" v-model="withdraw" placeholder="请输入提现金额"></el-input>
              <el-button type="primary" @click="withdraw = balance">
                全部提现
              </el-button>
            </div>
          </div>
          <div class="flex-a-c mb20">
            <div class="w200 text-align-right mr20 cA1 fs8 fwbold">
              最低提现金额：
            </div>
            <div class="flex-a-c">
              <p class="cA1 fs8 fwbold mr40" v-if="staffs.singleLimit">￥{{staffs.singleLimit }}</p>
              <p class="cA1 fs8 fwbold mr40" v-else>￥{{0}}</p>
            </div>
          </div>
          <div class="flex-a-c mb20">
            <div class="w200 text-align-right mr20 cA1 fs8 fwbold">
              当前可提现金额
            </div>
            <div class="flex-a-c">
              <p class="cA1 fs8 fwbold mr40">￥{{balance}}</p>
            </div>
          </div>
        </div>

        <div class="mt40 flex-c-c">
          <el-button type="primary" plain @click="cancel">
            取消
          </el-button>
          <el-button type="primary" @click="onConfirmShop">
            确认
          </el-button>
        </div>
    </el-dialog>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import { queryBossWithdrawalSetting, queryOrders,querySells,addBossWithdrawals,queryBossWithdrawals } from "@/api/financing";

export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      userId: "",
      sourcesId: "",
      dateList: [
        { id: 1, name: "近七天" ,date:7 },
        { id: 2, name: "近三十天" ,date:30},
        { id: 3, name: "近九十天" ,date: 90},
      ],
      authsId: "",
      phone: "",
      currentDateIndex:0,
      dialogTableVisible:false,
      endTime:'',
      startTime:'',
      userInfo:'',
      totalCommission:'', // 累计分销佣金
      totalPrice:'', // 累计商品金额
      totalDiscount:'', // 累计优惠金额
      totalSells:'',  // 累计商品收入
      balance:0, //可提现余额
      frozenCommission: 0 ,// 冻结资金
      withdraw:'', //将要提现的金额
      staffs:'', //提现配置
      isLoading:false,
      withdrawalList:'',
      payee:''
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  async created() {
    this.userInfo = JSON.parse(localStorage.getItem('info'))
    this.endTime = await this.getDay(0)
    this.payee = this.userInfo.userName
    this.queryBossWithdrawalSetting();
    this.queryBossWithdrawals()
    this.queryOrders();
    this.queryBossSells()
  },
  methods: {
    //查询提现设置
    async queryBossWithdrawalSetting() {
      try {
        const result = await queryBossWithdrawalSetting({ id: '' });
        
        if(result.data){
          this.staffs = result.data;
        }else{
          this.staffs = {
            singleLimit:0
          }
        }
      } catch (error) {
        
        console.log(error);
      }
    },
    async queryOrders() {
      console.log(this.endTime)
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        authPhone: this.authsId,
        userId: this.userId,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      try {
        this.loading = true;
        const result = await queryOrders(data);
        this.loading = false;
        if(result.data){
          const { total, list } = result.data.pageInfo;
          this.tableData = list;
          this.tableData.forEach(item=>{
            item.orderPrice = (item.orderPrice/100).toFixed(2)
            item.payPrice = (item.payPrice/100).toFixed(2)
            item.commission = (item.commission/100).toFixed(2)
            if(item.orderPrice !== item.orderPrice){
              item.discountAmount = item.orderPrice - item.payPrice
              
            }
          })
          this.total = total;
          this.totalCommission = result?.data.totalCommission
          this.totalDiscount = result?.data.totalDiscount
          this.totalPrice = result?.data.totalPrice
          this.totalSells = result?.data.totalSells
        }else{
          this.tableData = []
          this.total = '';
          this.totalCommission = ''
          this.totalDiscount = ''
          this.totalPrice = ''
          this.totalSells = ''

        }
        
      } catch (error) {
        this.tableData = []
        this.loading = false;
        console.log(error);
      }
    },
    //查询余额
    queryBossSells(){
      let data = {
        ignore:true
      }
      querySells(data)
      .then(res=>{
        console.log(res)
        this.balance = (res?.data.balance/100).toFixed(2)
        this.frozenCommission = (res?.data.frozenCommission/100).toFixed(2)
      })
      .catch(err => {
        console.log(err)
      })
    },
    //获取提款记录
    queryBossWithdrawals(){
      let data = {
        pageSize: 5,
        pageNum: 1,
        isReview: 1
      }
      queryBossWithdrawals(data)
      .then(res=>{
        console.log(res)
        this.withdrawalList = res?.data?.pageInfo.list
      })
      .catch(err=>{
        console.log(err)
      })
    },
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if(theTime > 60) {
        theTime1 = parseInt(theTime/60);
        theTime = parseInt(theTime%60);
        if(theTime1 > 60) {
          theTime2 = parseInt(theTime1/60);
          theTime1 = parseInt(theTime1%60);
        }
      }
      var result = ""+parseInt(theTime)+"秒";
      if(theTime1 > 0) {
        result = ""+parseInt(theTime1)+"分"+result;
      }
      if(theTime2 > 0) {
        result = ""+parseInt(theTime2)+"小时"+result;
      }
      return result;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryOrders()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryOrders()
    },
    dialogClose(){
      this.dialogTableVisible = false
      this.withdraw = 0
    },
    cancel(){
      this.dialogTableVisible = false
    },
    onConfirmShop(){
      if(this.isLoading == true){
        return
      }
      if(!this.userInfo.roleId){
        return this.$message.error('此行为非boss权限不可操作')
      }
      this.isLoading = true

      if(this.staffs.singleLimit > this.withdraw ){
        return this.$message.error('提现金额不得小于最低提现金额')
      }
      let data ={
        amount:this.withdraw
      }
      addBossWithdrawals(data)
      .then(res=>{
        if(res.code == 200){
          this.$message.success('提现成功')
          this.withdraw = ''
        }
        this.dialogTableVisible = false
        setTimeout(()=>{
          this.queryBossSells()
          this.isLoading = false
        },500)
      })
      .catch(err=>{
        this.dialogTableVisible = false
        setTimeout(()=>{
          this.isLoading = false
        },500)
        this.$message.error(err.message)
      })
    },
    selectDate(){
      this.currentDateIndex = ''
      this.startTime = this.pickerValue && `${this.pickerValue[0]} 00:00:00`
      this.endTime = this.pickerValue && `${this.pickerValue[1]} 23:59:59`
    },
    checkRecord(type){
      this.$router.push('/'+type)
    },
    async setDay(day){
      this.currentDateIndex = day
      this.pickerValue = ''
      this.startTime = await this.getDay(0,'start')
      this.endTime = await this.getDay(day)
      console.log(this.startTime)
      console.log(this.endTime)
    },
    getDay(day,type){
      var today = new Date();
      var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      if(type){
        return tYear+"-"+tMonth+"-"+tDate + ' 00:00:00';
      }else{
        return tYear+"-"+tMonth+"-"+tDate + ' 23:59:59';
      }
      
      
    },
    doHandleMonth(month){
      var m = month;
      if(month.toString().length == 1){
      m = "0" + month;
      }
      return m;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>